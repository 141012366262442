body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

button[disabled] {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.form-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.notification {
  position: fixed;
  width: 350px;
  top: 30px;
  right: -350px;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  z-index: 1050;
  background: white;
}

.notification > .notification-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000;
}

.notification.active {
  right: 10px;
}

.notification:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
